@import 'variables';
@import 'droppe-theme.less';

html,
label {
  font-size: @font-size-base;
  line-height: @line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: @headings-margin-bottom;
  line-height: @line-height-headings;
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
}

.typography-scale(@n; @i : 1) when (@i =< @n) {
  @heading: extract(@font-sizes, @i);
  @tag: extract(@heading, 1);
  @size: extract(@heading, 2);
  @{tag},
  .@{tag},
  .@{tag}-size {
    font-size: @size;
  }

  .typography-scale(@n; (@i + 1));
}

.typography-scale(length(@font-sizes));
