@import '../abstracts/variables';
@import '../abstracts/helpers.less';

.img-cell {
  padding: @spacer * 1.5;
  padding-right: 12px; // To perfectly center align icon
  padding-top: 13px; // To perfectly center align icon
  margin-right: 9px; // To make the whole element actually align correctly and be square
  background: @neutral-gray;
  border-radius: @border-radius;
  max-width: 64px;

  svg {
    color: @dim-mid;
  }
}
