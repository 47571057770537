@import 'abstracts/variables';
@import 'abstracts/helpers';
@import 'PageLayout.module';

@mobile-search-zIndex: 10;
@popover-gap: 6px;
@round-border-radius: 50px;

.search-modal-dialog {
  max-height: 80%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-height: 40px;
  max-height: 50px;
  margin: @spacer * 0.5 @spacer * 2 @spacer * 0.5 0;
}

/* stylelint-disable no-descending-specificity */ //
.search-modal-container {
  :global(.rs-modal-content) {
    max-height: 100%;
  }

  :global(.rs-stack-item) {
    width: 100%;
  }

  .hits-list {
    width: 100%;
    margin-bottom: @spacer * 2;

    mark {
      // Offset mark padding to not change alignment
      margin: -0.2em;
    }

    :global(.rs-list-item) {
      box-shadow: 0 -1px 0 var(--rs-border-primary), 0 1px 0 var(--rs-border-primary);
    }

    .hit-row {
      flex-wrap: nowrap;
      max-width: 100%;

      .text-box {
        min-width: 0;
        flex-shrink: 1;
      }
    }

    .name {
      .ellipsis;
    }

    .sku {
      color: @B600;
    }
  }

  .input {
    width: 100%;
    border-radius: @round-border-radius;
    padding-left: 1.5 * @spacer; // Visual negative space due to round input
    padding-right: 6.5 * @spacer; // When the input has clean button on right

    :global(.rs-picker-toggle-clean) {
      margin-right: 1.5 * @spacer;
      font-size: @font-size-base;
    }

    :global(.rs-input-group-addon).search-icon {
      padding-right: 2 * @spacer;
    }

    :global(.rs-input-group.rs-input-group-inside) {
      border-radius: @round-border-radius;
    }

    input {
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.search-container {
  position: relative;
  padding-left: @spacer * 2;
  flex-grow: 1;

  @media @below-lg {
    padding-left: 0;
  }

  .search-input-container {
    position: relative;

    :global {
      .rs-auto-complete {
        cursor: unset;

        input {
          border-radius: @round-border-radius;
          padding-right: 43px; // Magic number for search icon offset
        }

        &:hover,
        &-focus,
        &:active {
          background: none;
        }
      }

      .rs-picker-toggle-clean {
        font-size: 20px; // Matching figma
        z-index: @mobile-search-zIndex;
        margin-right: @spacer;
      }
    }

    // Search bar - Remove input focus outline
    > div:global(.rs-auto-complete) input {
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }

    // Search bar - Space between empty input X icon and search icon
    > span:global(.rs-picker-toggle-clean) {
      padding-right: @spacer * 2;
    }

    .search-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  .mobile-search-dropdown > div {
    padding: 0;
    margin-top: @spacer * 2;
    margin-bottom: @spacer;
  }

  .search-term {
    display: flex;
    align-items: center;
    gap: @spacer * 1.5;
    background: none;
    width: 100%;

    svg {
      min-width: 40px; // Prevent search icon from shrinking when search term is long
    }

    p { .ellipsis(); }

    &:hover {
      text-decoration: underline;
    }
  }

  .call-to-action {
    margin-top: @spacer * 2;
  }
}

// Outside container dom so needs to be set separately
.search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: @B800;
  z-index: @mobile-search-zIndex - 1;
  display: none;
  transition: opacity 0.5s;

  &.visible {
    display: block;
    opacity: 0.4;

    @media @below-lg {
      display: none;
    }
  }
}
