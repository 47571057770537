@import 'abstracts/variables';

.modal:global(.rs-modal) {
  margin-top: 120px; // Match figma design

  @media @below-sm {
    width: 100vw;
    max-width: 100vw;
    margin: 0;

    .login-form-welcome {
      width: 100%;
    }

    :global {
      .rs-modal-dialog {
        width: 100%;
        min-width: 0;

        .rs-modal-header {
          .rs-modal-header-close {
            font-size: 25px; // Match figma design
            top: @spacer * 2;
            right: @spacer * 3;
          }
        }

        .rs-modal-content {
          height: 100vh;
          padding: @spacer * 2;
        }
      }
    }
  }
}

// Center align on smaller screen when size is 'sm'
.modal.sm-modal {
  padding: @spacer * 2;

  :global {
    .rs-modal-dialog {
      .rs-modal-content {
        @media @below-sm {
          height: auto !important; // Overriding basemodal styles
        }
      }
    }
  }
}

.modal.bottomsheet {
  align-content: end !important;

  :global {
    .rs-modal-dialog {
      .rs-modal-content {
        @media @below-sm {
          height: auto !important; // Overriding basemodal styles
        }
      }
    }
  }
}

.modal-dialog {
  :global(.rs-modal-content) {
    text-align: center;
    padding: @modal-padding-large;
    padding-top: @spacer * 4;
  }

  :global(.rs-modal-header) {
    :global(.rs-modal-header-close) {
      top: @spacer * 2.75;
      right: @spacer * 2.75;
      line-height: 0;
      color: @B600;
    }
  }

  .modal-action-container {
    display: flex;
    justify-content: center;
    gap: @spacer * 2;
    padding: @spacer 0;

    :global(.rs-btn) {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .modal-text-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: @spacer * 1.5;
    padding: @spacer 0;
    text-align: left;
  }

  .close-button {
    color: @dim-mid;
    border: 1px solid @dim-mid;
  }
}

.modal.center {
  align-content: center;
  height: 100vh;
  display: grid !important;
  margin-top: 0;
}

.title {
  color: @B900;
}

.modal-body {
  color: @B800;
}
