@import 'variables';

@spacers: zero (0),
  spacer-half (@spacer / 2),
  spacer (@spacer),
  spacer-double (@spacer * 2),
  spacer-tripple (@spacer * 3),
  spacer-quadruple (@spacer * 4),
  form-group (@form-group-margin-bottom);

.spacers(@n; @i : 1) when (@i =< @n) {
  @spacer-row: extract(@spacers, @i);
  @selector: extract(@spacer-row, 1);
  @value: extract(@spacer-row, 2);

  .padding-@{selector} {
    padding: @value;
  }
  .padding-top-@{selector} {
    padding-top: @value;
  }
  .padding-left-@{selector} {
    padding-left: @value;
  }
  .padding-right-@{selector} {
    padding-right: @value;
  }
  .padding-bottom-@{selector} {
    padding-bottom: @value;
  }
  .margin-@{selector} {
    margin: @value;
  }
  .margin-top-@{selector} {
    margin-top: @value;
  }
  .margin-left-@{selector} {
    margin-left: @value;
  }
  .margin-right-@{selector} {
    margin-right: @value;
  }
  .margin-bottom-@{selector} {
    margin-bottom: @value;
  }
  .margin-@{selector}-negative {
    margin: -@value;
  }
  .margin-top-@{selector}-negative {
    margin-top: -@value;
  }
  .margin-left-@{selector}-negative {
    margin-left: -@value;
  }
  .margin-right-@{selector}-negative {
    margin-right: -@value;
  }
  .margin-bottom-@{selector}-negative {
    margin-bottom: -@value;
  }

  .spacers(@n; (@i + 1));
}

.spacers(length(@spacers));

.margin-bottom-zero-important {
  margin-bottom: 0 !important;
}
