@import '../abstracts/variables';
@import '../abstracts/helpers';
@import '../abstracts/droppe-theme';

// Buttons

.rs-btn-link {
  font-weight: 400; // Lighter link buttons like in v4
}

.rs-btn {
  transition: @btn-transition, @input-transition;
}

// Badges

.rs-badge.rs-badge-independent.size-lg {
  border-radius: 16px;
  padding: 3px 10px;
}

.rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover {
  color: @primary-darker;
}

// Icons

.rs-notification-info .rs-notification-content .rs-icon {
  color: @orange;
  flex-grow: 0;
  flex-shrink: 0;
}

.rs-notification-success .rs-notification-content .rs-icon {
  color: @primary-dark;
}

.flex-flow-row.align-baseline .flex-shrink-col-static .rs-icon {
  margin-top: -2.5px; // Magic number for visual vertical alignment of checkbox rows
}

// Sidenav

.rs-sidenav.no-wrap-no-overflow {
  .rs-sidenav-header {
    .no-wrap-no-overflow;
  }

  .rs-nav-item {
    .no-wrap-no-overflow;
  }
}

// Panels

.rs-panel {
  // V5 overrides
  border-radius: @border-radius;

  // Custom spacing rules for panel headings
  .rs-panel-header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }
}

.rs-panel-collapsible .rs-panel-header > *:only-child::before {
  @dim: 1em; // Match dimensions for svg

  content: '';
  display: block;
  // Match rsuite Panel // &-collapsible > &-header > .rs-icon rule
  position: absolute;
  top: 24px;
  right: 20px;
  height: @dim;
  width: @dim;
  transition: transform 0.3s linear;
  .caret-down-icon-background(@dim: @dim);
}

.rs-panel-collapsible.rs-panel-in .rs-panel-header > *:only-child::before {
  transform: rotate(180deg);
}

// Dropdowns

// Override the existing dropdown caret icon with the same icon we use for the panel header
.rs-nav .rs-dropdown svg[aria-label='arrow down line'] {
  // Only happens when Dropdown wrapped into Nav
  .caret-down-icon-background();

  path {
    display: none;
  }
}

.rs-picker-toggle-label {
  color: var(--rs-text-secondary);
  font-weight: 400;
}

.rs-modal-open .rs-picker-menu {
  z-index: @z-index-modal;
}

// Avatars

.rs-avatar-image::before {
  // When image is missing we fill placeholder with attr(alt), ensure sensible font-size
  font-size: @font-size-base;
}

// Forms

.rs-input-group .rs-picker-input {
  border: none; // Avoid double borders for pickers in input groups
}

.rs-tag {
  border-radius: @border-radius;
}

.rs-form {
  // Target an input that has an active error state
  [aria-invalid='true'],
  .rs-picker-toggle[aria-invalid='true'] {
    border-color: var(--rs-form-errormessage-text);

    &:focus {
      outline: 3px solid fade(@red, 25%); // matches the --rs-color-focus-ring in rsuite
      border-color: var(--rs-form-errormessage-text);
    }

    &:hover {
      border-color: var(--rs-form-errormessage-text);
    }
  }
}

.rs-radio-group-picker .rs-radio-checker {
  label {
    &:hover {
      color: @primary-darker;
    }
  }
}

.rs-radio-group-picker .rs-radio-checked .rs-radio-checker {
  border-color: @primary-darker;

  label {
    color: @primary-darker;

    &:hover {
      color: @primary-darker;
    }
  }
}

// Breadcrumbs

.rs-breadcrumb {
  padding: 8px 15px; // This was removed in v5
}

.rs-breadcrumb-separator {
  // Visually align with breadcrumb text baseline
  position: relative;
  top: -1px;
}

// Errors
.rs-message-error .rs-message-header {
  font-weight: @headings-font-weight;
  font-size: @font-size-large;
}

@media print {
  .chaport-container {
    display: none !important;
  }
}

// Align multi picker counter to the right
.rs-picker-toggle .rs-picker-toggle-value {
  justify-content: space-between;
}

// Tooltip
.rs-tooltip {
  color: @B800;
  background-color: white;
  filter: drop-shadow(var(--rs-popover-shadow));
  padding: @font-size-small;
  text-align: left;
  z-index: @z-index-tooltips;

  &.placement-top-end::after {
    color: @B800;
    border-top-color: white;
  }
}

// Readonly fields

.rs-input:read-only {
  cursor: not-allowed;
  color: @B700;
}

.rs-picker-read-only {
  .rs-picker-toggle {
    cursor: not-allowed;

    .rs-picker-toggle-value {
      color: @B700;
    }
  }
}

.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) > :last-child {
  border-radius: @border-radius - 1px; // Avoid input group addon button border radius gap versus input group border
}

// Modals
.rs-modal-wrapper {
  z-index: @z-index-modal;
}

.rs-modal-backdrop {
  z-index: @z-index-modal;
}

.language-modal-open .rs-modal-wrapper {
  z-index: @z-index-language-modal;
}

.language-modal-backdrop.rs-modal-backdrop {
  z-index: @z-index-language-modal;
}

.rs-toast-container {
  z-index: @z-index-chaport;
}

// Notification - allow long headers
.rs-notification-title-with-icon {
  white-space: normal;
  align-items: flex-start;
}

// Required to fix picker + modal behavior. When modal gets opened while picker is opened
// very low on page the modals backdrop overflow and scroll behavior is broken (page gets stretched vertically to pickers position)
.rs-modal-open.hide-rs-pickers .rs-picker-menu {
  display: none;
}

// Tooltip arrow color override - it took `currentColor` value
// and resulted in a black arrow for example
.rs-tooltip[class*='placement-top']::after {
  border-top-color: white;
}
