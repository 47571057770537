@import 'abstracts/variables';

.container {
  color: @B600;

  span {
    line-height: 20px; // Aligning text and icon vertically
  }

  svg {
    path {
      fill: @B600;
    }

    // Selecting the check mark inside
    path:nth-child(2) {
      stroke: @B600;
    }
  }

  &.variant-dark {
    color: @B800;

    svg {
      path {
        fill: @B800;
      }

      // Selecting the check mark inside
      path:nth-child(2) {
        stroke: @B800;
      }
    }
  }
}
