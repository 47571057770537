@import 'abstracts/variables';

.notification-header-container {
  width: 100%; // Fill out container
  border-bottom: 1px solid var(--rs-divider-border);
  background-color: @teal-mid;
  z-index: @zindex-navbar-fixed + 1; // Always show on top of header due to scroll animations
  padding: @spacer * 0.75 @spacer * 2.5 @spacer * 0.75 @spacer * 2;

  @media @below-md {
    display: flex;
    align-items: center;
  }

  @media @below-sm {
    padding: @spacer !important;
  }

  .inner-container {
    // Override the max-width-lg class horizontal margin-auto
    @media @below-md {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .arrow-icon {
    margin-left: 3px; // Magic number for horisontal spacing
  }

  .icon-vertical-alignment {
    margin-top: -2px; // Magic number for vertical alignment with text
  }
}
