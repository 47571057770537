@import 'abstracts/spacers';
@import 'abstracts/helpers';
@import 'abstracts/variables';

.title {
  font-size: @font-size-base + 22;
  margin-bottom: @spacer * 2;
  line-height: @line-height-base - 0.7;

  @media @below-md {
    font-size: @font-size-base + 4.5;
    margin-top: @spacer * 3;
    margin-bottom: @spacer * 2.5;
  }
}

.subtitle {
  font-size: @font-size-base + 2;
  color: @B600;
  margin-bottom: @spacer * 3;

  @media @below-md {
    font-size: @font-size-base;
    margin-bottom: @spacer * 2.5;
  }
}

@button-font-size: @font-size-base + 2;

.contact-button {
  background: none;
  padding: 0;
  margin-left: @spacer * 3;
  font-family: @font-family-base;
  font-size: @button-font-size;
  font-weight: 600 !important;

  &:focus {
    outline: none;
  }

  @media @below-sm {
    margin-left: 0;
    margin-top: @spacer * 2.5;
  }
}

button.return-button {
  font-family: @font-family-base;
  font-size: @button-font-size;
  font-weight: 400;
  line-height: @line-height-base;
  background-color: @primary;
  padding: @spacer - 1 @spacer + 8;

  @media @below-md {
    padding: @spacer * 1.5 @spacer + 8;
  }
}

.error-panel {
  margin-top: @spacer * 3;
  max-width: @spacer * 42;

  :global {
    .rs-panel-header {
      padding: @spacer + 4 @spacer * 2;
      color: @B800;

      svg.rs-icon {
        top: @spacer + 8;
      }
    }

    .rs-panel-body {
      color: @B600;
      max-height: @spacer * 13;
      overflow: hidden scroll;
      word-break: break-word;
      white-space: pre-line;
      line-height: @line-height-base + 0.214;
      font-weight: 400;
    }
  }

  @media @below-md {
    margin: auto;
    margin-top: @spacer * 2.5;
    text-align: left;
  }

  @media @below-sm {
    max-width: 100%;
  }
}

.panel-wrapper {
  height: @spacer * 20;

  @media @below-md {
    min-height: 100px;
    height: auto;
  }
}

.fallback-container {
  padding-top: @spacer * 13;

  @media @below-md {
    margin-top: @spacer * 5;
    padding: 0;
    flex-direction: column-reverse !important;
    text-align: center;
    align-items: center !important;
  }
}

.content-container {
  max-width: @spacer * 50;

  @media @below-md {
    margin: @spacer * 2;
  }
}
