@import 'abstracts/variables';

.mobile-blocker {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: @spacer*4;
  align-items: center;
  justify-content: center;
  background-color: @B000;
  z-index: @z-index-mobile-blocker;

  h1 {
    margin-bottom: @spacer;
  }

  .max-width {
    position: relative;
    max-width: 500px; // Visual purposes
    margin: 0 auto;

    .browse-products-button {
      padding: @spacer @spacer * 2;
      color: @primary-darker;

      &:hover,
      &:focus {
        color: @primary-darker;
        text-decoration: none;
      }
    }
  }

  .logo-positioning {
    position: absolute;
    top: -25vh;
    left: 0;
  }
}

// In order to seem like the modal is opened on top of the mobile blocker, we add these
// rules to the backdrop so it will be a blank white page
.mobile-backdrop {
  background-color: @B000 !important;
  opacity: 1 !important;
  z-index: @z-index-mobile-blocker !important;
}
