@import 'abstracts/variables';

.droppe-logo {
  max-width: fit-content;
  width: auto; // Safari fit-content fix

  .alignment-fix(@x) {
    // To remove the svg inner padding, alignment of logo to left and right side easier
    &.align-left {
      margin-left: -@x;
    }

    &.align-right {
      margin-right: -@x;
    }
  }

  &.size-sm {
    max-height: 2em;
    @svg-inner-padding-x: 0.4em;
    .alignment-fix(@svg-inner-padding-x);
  }

  &.size-sd {
    max-height: 2.5em;
    @svg-inner-padding-x: 0.4em;
    .alignment-fix(@svg-inner-padding-x);
  }

  &.size-lm {
    max-height: 3em;
    @svg-inner-padding-x: 0.4em;
    .alignment-fix(@svg-inner-padding-x);
  }

  &.size-md {
    max-height: 3.5em;
    margin-bottom: -0.6em;

    @svg-inner-padding-x: 0.65em;
    .alignment-fix(@svg-inner-padding-x);
  }

  &.size-lg {
    max-height: 6em;

    @svg-inner-padding-x: 1.2em;
    .alignment-fix(@svg-inner-padding-x);
  }

  &.align-center {
    margin-left: auto;
    margin-right: auto;
  }

  path {
    fill: @dim-mid;
  }

  &.white {
    path {
      fill: @white;
    }
  }

  &.teal-dark {
    path {
      fill: @teal-dark;
    }
  }
}
