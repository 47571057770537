@import 'abstracts/variables';

// Magic number: Product nav max width to stop menu items from spreading too much. Matches Figma and enables better responsiveness.
@product-nav-max-width: 639px;
@product-nav-transition: transform 0.5s;
@product-nav-background-zindex: @zindex-navbar-fixed - 2;
@headerHeight: 113.6px;
@notificationbarHeight: 37px;

.scrolling-up-mixin() {
  &.scrolling-up {
    transition: @product-nav-transition;
    // transform added dynamically at component level
  }

  &.scrolling-down {
    transition: @product-nav-transition;
    transform: translate3d(0, 0, 0);
  }
}

// Mixin for arrow fade background
.gradient-background-mixin(@dir) {
  background: ~'linear-gradient(@{dir}deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(0,0,0,0) 100%)';

  &:active,
  &:focus,
  &:hover,
  &:target {
    background: ~'linear-gradient(@{dir}deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(0,0,0,0) 50%)';
  }
}

.header-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px; // Magic number: Match product nav height
  z-index: @product-nav-background-zindex; // Always hide below main header
  background: @header-background;
  transition: @product-nav-transition;
  transform: translate3d(0, 0, 0);
  box-shadow: @default-box-shadow;

  .scrolling-up-mixin();
}

.single-product-nav {
  overflow: hidden;
  position: relative;
  padding: (@spacer * 2) 0;
  z-index: @product-nav-background-zindex + 1; // Always show above product nav background
  background: none;
  transition: @product-nav-transition;

  .scrolling-up-mixin();

  &.sticky-nav {
    position: fixed;
    top: 0;
    max-width: @product-nav-max-width;
    width: 100%;
    margin: 0;

    @media @below-lg {
      max-width: unset;
      margin: 0 auto;

      .nav-scroll-button {
        display: none;
      }
    }

    @media @below-md {
      max-width: unset;
      margin: 0 auto;

      .nav-scroll-button {
        display: unset; // Only hide in large screen sticky navs
      }
    }
  }

  :global {
    .rs-navbar-nav {
      float: unset;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: @product-nav-max-width;
      width: 100%;
      margin: 0;
      padding: 0;
      -ms-overflow-style: none;  // Hide scrollbar Firefox
      scrollbar-width: none;  // Hide scrollbar IE + Edge

      &::-webkit-scrollbar {
        display: none; // Hide scrollbar chrome
      }

      .rs-navbar-item {
        padding: 0 !important; // Override product class
        height: auto;
        color: @B900;
        font-size: @font-size-base + 2;
        overflow: unset;
        cursor: pointer;

        &.active {
          font-weight: 600;
        }

        &:hover {
          color: @B900;
          text-decoration: underline;
        }
      }

      @media @below-lg {
        gap: @spacer * 3;
        margin: 0 auto;
        overflow: auto;
      }

      @media @below-md {
        padding: @spacer * 2;
      }
    }
  }

  .nav-scroll-button {
    display: flex;
    align-content: center;
    position: absolute;
    width: fit-content;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: opacity 0.2s ease-in;

    &.left {
      left: 0;
      .gradient-background-mixin(90);
    }

    &.right {
      right: 0;
      .gradient-background-mixin(270);
    }

    &.hidden {
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }

    &:active,
    &:focus,
    &:hover,
    &:target {
      svg > path {
        fill: @primary-darker;
      }

      svg > circle {
        stroke: @primary-darker;
      }
    }
  }

  @media @below-md {
    border-top: 1px solid @B200;
    border-bottom: 1px solid @B200;
    padding: 0;
    overflow: visible;
    width: 100vw !important;
    margin-left: -(@spacer * 2) !important; // Important overrides sticky header value
  }
}
