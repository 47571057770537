@import 'abstracts/variables';

.layout-container {
  min-height: 100vh;
  max-width: 100vw;

  .desktop-header {
    @media @below-lg {
      display: none;
    }
  }

  .mobile-header {
    display: none;

    @media @below-lg {
      display: block;
    }
  }
}

.superuser-toolbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: @z-index-chaport + 1;
  transform: translateX(-50%);
}
