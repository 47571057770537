@import 'abstracts/variables';

@country-picker-content-width: 310px;

.vertical-align-svg {
  // Mixin class
  svg {
    vertical-align: middle;
  }
}

.selected-country {
  color: @primary-darker;
}

.selected-lang {
  color: @primary-darker;
}

.pointer {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.country-picker-content {
  font-weight: 500;

  @media @below-tablet-header-breakpoint {
    max-width: @country-picker-content-width;
    margin: 0 auto;
  }
}

.country-picker-popover {
  @shadow-y: 10px;
  @shadow-x: 4px;

  width: @country-picker-content-width;
  font-weight: 500;
  border-radius: @border-radius-base !important;
  box-shadow:
    0 0 @shadow-y rgba(0, 0, 0, 0.06),
    0 @shadow-x @shadow-x rgba(194, 194, 194, 0.12);

  :global {
    .rs-popover-content {
      margin-top: @spacer;
      font-size: @font-size-base;
      padding-right: @spacer;
    }

    .rs-popover-arrow {
      display: none !important; // hide popover tooltip
    }
  }

  .locales {
    font-size: @font-size-base - 2;
    margin-left: auto;
  }

  .vertical-align-svg();
}

.country-picker {
  font-weight: 500;
  cursor: pointer;

  .vertical-align-svg();
}

.icon {
  @dim: 18px; // Magic number for visual hierarchy to match text height with icon

  width: @dim;
  height: @dim;
  color: @headings-color;
  // Magic number to match visual spacing between text and icon equal to text and flag
  margin-bottom: 2px;
}

.country-label {
  font-weight: 500;
  margin-left: (@spacer / 2);
}

.mobile-country-picker {
  &-drawer-header {
    &:global(.rs-drawer-header) {
      border-bottom: none; // Remove the default border separator to match the design
    }
  }

  :global(.rs-drawer-body) {
    padding: @spacer @spacer * 4 @spacer * 3 @spacer * 4;
    height: initial !important; // Override the drawer header + body auto height value
  }
}
