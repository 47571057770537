@import 'abstracts/variables';
@import 'abstracts/helpers';

.product-logo {
  @avatar-dimension: 80px;

  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: @avatar-box-shadow-alternate;
  border-radius: 50%;
  margin-right: @spacer * 2;

  &.imported {
    border-radius: 0;
    box-shadow: none;
  }

  @media @below-md {
    margin-right: @spacer;
  }

  img {
    padding: @spacer * 1.5 !important;
    max-width: 100%;
    object-fit: contain;
    background-color: white;
  }
}

.order-info {
  :global(.rs-panel-body) {
    padding: @spacer * 3;
  }

  .order-info-container {
    flex-flow: row;

    @media @below-md {
      .flex-direction-column;
    }

    a {
      color: @headings-color;
    }

    .product-info {
      flex-grow: 1;
      flex-shrink: 1;

      @media @below-md {
        width: 100%;
        margin-top: @spacer * 2;
      }

      a {
        color: @headings-color;
      }

      .discount-text {
        font-size: @font-size-base - 2;
        color: @primary-darker;
        margin-left: (@spacer / 2);
      }
    }
  }
}

.modify-quantity-button {
  --rs-btn-ghost-border: @orange;
  --rs-btn-ghost-text: @orange;
  --rs-btn-ghost-hover-border: @orange-darker;
  --rs-btn-ghost-hover-text: @orange-darker;

  padding: @spacer @spacer * 2;
}
