@import 'abstracts/variables';

@positioning-x: 20px;
@dropdown-textarea-width: 60px;

.short-input-picker-alignment {
  :global(.rs-btn) {
    padding-top: 8px !important;
  }
}

.textarea-wrapper {
  position: relative;

  .long-input-picker-alignment {
    :global(.rs-btn) {
      padding-top: 4px !important;
    }
  }

  textarea {
    // Preventing language dropdown from overlapping
    padding-right: (@positioning-x * 2) + @dropdown-textarea-width;
  }
}

.arrow-alignment-country-picker {
  svg {
    padding-top: 1px;
  }
}

.lang-dropdown:global(.rs-picker) {
  // Limit picker width
  min-width: @dropdown-textarea-width;
  max-width: @dropdown-textarea-width;
  transition: border @transition-delay ease-in-out;

  &.lang-dropdown-textarea {
    position: absolute;
    top: @spacer;
    right: 25px;
    border: 1px solid var(--rs-border-primary);
    border-radius: @border-radius;
  }
}

@dropdown-short-width: 65px; // Magic number

.lang-dropdown-short:global(.rs-picker) {
  border-right: 1px solid var(--rs-border-primary);
  border-radius: @border-radius 0 0 @border-radius;

  // Extend width to avoid truncating language names
  min-width: @dropdown-short-width;
  max-width: @dropdown-short-width;
  position: absolute;
  top: 0;
  left: 0;

  // Divider
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: @B200;
    z-index: @zindex-picker-toggle;
  }
}

.short-input-with-lang-dropdown {
  &:global(.rs-input) {
    padding-left: @dropdown-short-width + @spacer;
  }
}

.product-name-preview {
  color: @B800;
}

.missing-data {
  &:global(.rs-input) {
    border: 1px solid var(--rs-form-errormessage-text);
  }
}
