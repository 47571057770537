@import 'abstracts/variables';

.popover {
  @shadow-y: 4px;
  @shadow-x: 0;

  border-radius: @border-radius-base !important;
  box-shadow:
    0 0 @shadow-y rgba(0, 0, 0, 0.06),
    0 @shadow-x @shadow-x rgba(194, 194, 194, 0.12) !important;
}

.picker-popover {
  .popover;

  width: 220px;
  color: @B800;
  padding: 0 !important;

  .label {
    margin-left: @spacer * 1.5;
  }

  .active {
    color: @primary-darker;

    &:hover {
      color: @primary-darker;
    }
  }

  :global {
    .rs-popover-content {
      margin-top: @spacer;
      font-size: @font-size-base;
      padding: @spacer * 2;
      padding-top: 0;
    }

    a {
      color: @B800;

      &:hover {
        color: inherit;
      }
    }

    .rs-popover-arrow {
      display: none !important; // Hide popover tooltip
    }

    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.profile-icon {
  &:hover {
    path {
      stroke: @primary-darker;
    }

    circle {
      stroke: @primary-darker;
    }
  }
}

.sidenav-logout {
  color: @red !important;
}
