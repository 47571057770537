@import 'abstracts/variables';

button.select-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: @spacer;
  border: 1px solid @B300;
  transition: border-color 0s; // Override default transition
  white-space: normal;

  &.direction-row {
    flex-direction: row;
  }

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.active,
  &:hover.rs-btn {
    border-color: @dim-mid;
    background-color: @teal-mid;
  }

  .check-icon-wrapper {
    position: absolute;
    height: 24px;
    width: 24px;
    top: -1px;
    right: -1px;
    background: @dim-mid;
    border-radius: 0 0 0 10px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}
