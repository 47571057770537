@import 'abstracts/variables';
// Import footer variables
@import 'Footer.module';
@import 'CountryPicker.module';

.footer-bottom-row {
  background: @teal-mid;

  .footer-bottom-row-inner {
    display: flex;
    justify-content: space-between;
    max-width: @max-width-lg;
    margin: 0 auto;
    padding: @spacer * 3 @spacer * 4;

    @media @below-lg {
      flex-direction: column;
    }

    .footer-copyright {
      flex-shrink: 0;
      margin-bottom: 0;
    }

    ul {
      display: inline-flex;
      list-style-type: none;
      margin-bottom: 0;

      @media @below-lg {
        padding-left: 0;
        padding-top: @spacer * 2.5;
      }

      @media @below-md {
        display: block;
      }

      li {
        margin-left: @spacer * 2;
        margin-right: @spacer * 2;

        .footer-link {
          color: @black;
        }

        &.country-dropdown {
          div {
            padding-top: 0;
            margin: 0;
            font-weight: 400;

            // Arrow icon
            svg {
              fill: @black;
              top: 0;
            }
          }
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        @media @below-md {
          margin: @spacer * 2 0;

          a.footer-link {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}
