@import 'abstracts/variables';
@import 'abstracts/helpers';

.checkout-header {
  position: sticky;
  display: flex;
  padding: @spacer @spacer * 3;
  top: 0;
  border-bottom: 1px solid var(--rs-divider-border);
  width: 100%;
  z-index: @zindex-picker-menu + 1; // Native rsuite variable
  background-color: @white;

  @media @below-lg {
    padding: @spacer 0;
  }

  svg {
    vertical-align: middle;
  }

  .checkout-header-content {
    width: 100%;
    max-width: @max-width-lg !important;
    padding: 0 @spacer * 3 0 @spacer * 6.5;
    gap: @spacer * 2;
    flex-wrap: nowrap;

    @media @below-xxl {
      padding-left: @spacer * 3;
    }

    .value-props {
      display: flex;
      flex-wrap: nowrap;
      gap: @spacer * 4;

      @media @below-lg {
        flex: 1;
      }
    }
  }

  .order-time-icon {
    width: 17px;
    height: 18px;
  }
}
