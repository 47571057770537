@import 'abstracts/variables';
@import 'abstracts/helpers';

// Used to calculate accurate horizontal divider height and navigation padding
@main-navigation-height: 86px;
@main-navigation-padding: (@spacer * 1.5);

.nav-icon-badge-styles-mixin {
  :global {
    .rs-badge-wrapper .rs-badge-content {
      bottom: -7px;
      top: unset;
      right: 5px;
      background: @primary-dark;
      color: @dim-darker;
      font-weight: 600;
      line-height: 1.3;
      padding: 1px 6px;
      border-radius: 50%;
    }
  }
}

.main-navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: @main-navigation-padding @spacer * 2;
  height: @main-navigation-height;

  .country-picker-nav {
    position: relative;
  }

  .nav-login-text {
    min-width: fit-content;
    margin: 0 @spacer;
    cursor: pointer;

    @media @below-tablet-header-breakpoint {
      margin-left: @spacer;
    }
  }

  @media @below-lg {
    padding: @spacer * 1.5 @spacer * 3;
    padding-bottom: (@spacer/2);
  }

  .header-nav-button {
    min-width: fit-content;

    @media @below-tablet-header-breakpoint {
      margin-left: @spacer;
      padding: @spacer !important;
    }
  }

  .header-brand-container {
    display: flex;
    align-items: center;

    .logo {
      margin-left: 3px; // Magic number to align logo exactly with header text
    }
  }

  :global {
    .rs-dropdown .rs-dropdown-item-with-icon i {
      padding-right: @spacer*2;
    }
  }

  // Control individual icon positioning, colors and hover styles
  .icons-wrapper {
    display: flex;
    align-items: center;

    button {
      padding: @spacer;
    }

    .nav-icon {
      .nav-icon-badge-styles-mixin;

      &.catalog-icon {
        margin-left: @spacer;
        top: 2px;

        svg {
          width: 28px;
          height: 28px;
          top: -3px;
          position: relative;
          color: @dim-darker;
        }

        &:hover {
          path {
            fill: @primary-darker;
            stroke: @primary-darker;
          }
        }
      }

      &.heart-icon {
        top: 1px;

        :global {
          .rs-badge-wrapper .rs-badge-content {
            position: absolute;
            top: unset;
            bottom: -6px;
          }
        }

        &:hover {
          path {
            stroke: @primary-darker;
          }
        }
      }

      &.profile-icon {
        top: -2px;
      }

      &.cart-icon {
        top: 1px;

        :global {
          .rs-badge-wrapper .rs-badge-content {
            bottom: -4px;
          }
        }

        &:hover {
          path {
            stroke: @primary-darker;
          }
        }
      }
    }
  }

  div.vertical-divider {
    height: @main-navigation-height;
    margin-bottom: -@main-navigation-padding;
    margin-top: -@main-navigation-padding;
  }
}

.logout-btn {
  margin-left: @spacer * 3;
}

.mobile-nav-grid {
  margin-bottom: @spacer;

  .mobile-nav-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media @below-lg {
      padding-left: @spacer * 2;
      padding-right: @spacer;
    }

    .nav-icons-alignment {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding-right: @spacer;

      .nav-icons-group1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: @spacer * 2.5;

        @media @below-sm {
          gap: @spacer * 1.5;
        }

        .mobile-select-product-icon {
          margin-bottom: 5px;
        }
      }

      .nav-icons-width {
        margin-left: @spacer * 2;
      }

      .nav-icons-width-desktop {
        max-width: 100%;
        margin-left: @spacer * 17;
      }

      .cart-icon-nav {
        position: relative;
        margin-bottom: 4px;
        margin-right: @spacer;

        .vertical-divider {
          height: 30px;
          border: 1px solid @B200;
          margin-left: @spacer * 3;
          position: absolute;
          top: 3px;
          left: -12px;
        }
      }

      .internal-traffic {
        width: 34px;
      }

      .mobile-search {
        margin-bottom: 6px;
      }
    }
  }
}

.catalog-popover-width {
  max-width: 250px;
}
