@import 'abstracts/variables';

.category-header {
  padding: @spacer * 2 @header-spacer-x;
  // Results in @header-spacer-x when adding div item margin-bottom
  padding-bottom: 0;
  margin: 0 -@spacer * 2;

  .nav-links {
    overflow: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  :global(.rs-flex-box-grid) {
    div {
      margin-right: @spacer * 5;
      // Adds bottom margin for wrapping menu items
      margin-bottom: @spacer * 2;

      @media @below-xl {
        margin-right: @spacer * 2;
      }
    }
  }

  a:not(.selected-category) {
    color: @B800;
  }

  .selected-category {
    color: @primary-darker;
    font-weight: 700;
  }
}
