@import 'abstracts/variables';

@tooltip-z-index: 1;

.internal-link-button {
  font-family: @headings-font-family;

  .internal-link-button-email {
    max-width: 93px; // Magic number to match design
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global {
    .rs-btn.rs-btn-icon-with-text {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: @spacer;
      padding: (@spacer / 2) @spacer !important; // Override needed
      background-color: white;
      border: @primary-darker 1px solid;
      border-radius: 50px; // Magic number for pill shape
    }
  }

  .internal-cookie-icon-button {
    &:global(.rs-btn-icon.rs-btn) {
      margin-right: @spacer * 2.5;
      background: none;
      padding: 0;

      svg {
        width: 30px;
        height: 30px;
      }

      &:focus,
      &:active,
      &:hover {
        background: none;

        svg circle {
          fill: @teal-mid;
        }
      }
    }
  }

  &.copy-link-popover {
    padding: @spacer * 2;
    z-index: @zindex-navbar-fixed + 2;

    .internal-link-button-user {
      margin-bottom: @spacer * 1.5;
      font-size: @font-size-base + 2;

      h5 {
        margin-bottom: 0 !important;
      }
    }

    :global {
      .rs-input-group {
        max-width: 80vw; // Making responsive for very small screen size
        width: 300px; // Magic number to match design
        font-family: @font-family-base;

        .rs-input {
          color: @B800;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .rs-dropdown-item.rs-dropdown-item-focus {
        background-color: white;
        cursor: initial;
      }

      .rs-input-group-btn,
      .rs-input-group-btn:hover,
      .rs-input-group-btn:focus {
        display: flex;
        justify-content: center;
        padding: 0 @spacer * 3;
        background-color: @primary;
        color: @B000;
        font-weight: 800;
      }
    }

    @media @below-sm {
      &:global(.rs-popover).popover-center {
        left: 50% !important; // Override inline style
        transform: translateX(-50%);
      }
    }
  }

  &.email-tooltip {
    font-size: @font-size-base;
    color: @text;
    background-color: white;
    z-index: @zindex-navbar-fixed + 1;

    &::after {
      border-bottom-color: white !important; // Override tooltip arrow color
    }
  }
}
