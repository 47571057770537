@import 'abstracts/variables';

.wrapper {
  .header {
    display: flex;
    flex-direction: column;

    p {
      color: @B800;
    }
  }

  .body {
    color: @B800;
    margin: @spacer * 2 0;
    max-height: 50vh;
    overflow: auto;

    .customer-type-buttons-wrapper {
      gap: @spacer * 2;

      button {
        flex: 1;
        min-height: 148px; // Magic number to ensure button heights are consistent and match Figma

        @media @below-md {
          min-height: 88px;  // Magic number to ensure button heights are consistent and match Figma
        }

        .unselected {
          width: 48px;
          height: 48px;
          border-radius: 100px;
          background-color: @neutral-gray;
        }

        .text-content {
          margin-top: @spacer * 1.5;

          p.subtitle {
            font-weight: 400;
            color: @text;
          }

          @media @below-md {
            text-align: start;
            margin-top: 0;
            margin-left: @spacer;
          }
        }
      }

      @media @below-md {
        flex-direction: column;
        gap: @spacer;
      }
    }
  }
}
