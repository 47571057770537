@import 'abstracts/variables';

.warning-bar {
  color: @warning-dark !important;

  &.full-width {
    background: @warning-lighter;
    border-radius: @border-radius;
    padding: @spacer @spacer * 2.5;

    h6 {
      font-weight: 700;
      color: @warning-dark;
      margin: 0;
    }
  }

  &.inline-text {
    margin-top: @spacer * 1.5;
  }

  .supplier-link {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  :global(.rs-icon) {
    color: @warning-dark;
  }
}

.popover {
  width: 252px;
}
