@import 'abstracts/variables';

.img-container {
  > span {
    position: unset !important;

    .img {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;

      img {
        &::before {
          // Prettier breaking of images
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: @primary;
        }
      }
    }
  }
}
