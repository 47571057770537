@import 'abstracts/variables';

.primary-dark-filled {
  path {
    stroke: @primary-dark;
    fill: @primary-dark;
  }
}

.heart-icon {
  fill: none;
  width: 30px;

  &.dark {
    path {
      stroke: @dim-darker;
    }
  }

  &.light {
    path {
      stroke: @B800;
    }
  }

  &.primary {
    path {
      stroke: @primary;
    }
  }

  &.primary-dark {
    path {
      stroke: @primary-dark;
    }
  }

  &.primary-dark-filled {
    path {
      stroke: @primary-dark;
      fill: @primary-dark;
    }
  }

  &.primary-filled {
    path {
      stroke: @primary;
      fill: @primary;
    }
  }

  @media @below-lg {
    height: 16px;
    width: 16px;
    position: relative;
    display: flex;
  }
}
