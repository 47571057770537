@import 'abstracts/variables';

// All the common cart popup styles
.common-cart-popup-styles {
  min-width: 450px;
  max-width: 550px;
  padding: @spacer * 3;
  padding-bottom: @spacer * 2;
  border-radius: @border-radius;
  background: @B000;
  box-shadow: 0 0 15px 0 rgba(@B600, 0.3);
  z-index: @zindex-navbar-fixed + 1;

  @media @below-sm {
    min-width: unset;
    padding: 0;
    padding-top: @spacer * 2;
  }

  .checkout-button {
    padding: @spacer @spacer * 3;

    @media @below-md {
      height: 50px;
      margin-top: @spacer * 1.5;
    }
  }

  .continue-button {
    display: none;

    @media @below-sm {
      display: block;
      margin-top: @spacer * 1.5;
      margin-bottom: @spacer;
    }

    button {
      height: 50px;
    }
  }
}

.cart-popup-footer {
  margin-top: @spacer * 2.5;
  margin-bottom: @spacer * 0.5;

  @media @below-sm {
    display: block !important;
    padding: @spacer * 2;
    padding-top: 0;
    padding-bottom: (@spacer/2);
    margin-bottom: 0;
  }
}

.cart-popup {
  &.add-to-cart-popup {
    position: fixed;
    top: 12px;
    right: 12px;

    .common-cart-popup-styles;
  }

  &.header-cart-popup {
    .common-cart-popup-styles;

    :global(.rs-popover-content) {
      font-size: @font-size-base;
    }

    .cart-popup-divider {
      // Avoiding layout shift with position absolute
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      margin: 0;
      z-index: 1;
    }

    .product-list-outer-container {
      position: relative;

      @media @below-sm {
        padding: @spacer * 2;
        padding-top: 0;
      }

      .product-list-container {
        max-height: 360px; // Match Figma
        overflow-x: hidden;

        :first-child {
          margin-top: 0;
        }

        :last-child {
          margin-bottom: 1px; // To display bottom border
        }
      }

      .list-gradient {
        position: absolute;
        bottom: 0;
        height: 85px;
        background: linear-gradient(0deg, #fff 1.87%, rgba(255, 255, 255, 0) 87.75%);

        // Removing 15px so that gradient doesn't cover scrollbars
        width: calc(100% - 15px);

        @media @below-sm {
          width: calc(100% - 30px);
        }
      }
    }
  }
}
