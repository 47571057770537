@import 'abstracts/variables';

.change-language-modal {
  :global {
    .rs-modal-header {
      padding-right: 0;
    }

    .rs-modal-body {
      margin-top: @spacer;
    }
  }

  .country-picker-wrapper {
    border: 1px solid @B050;
    border-radius: @border-radius;
    max-width: 320px; // Match Figma
    margin: 0 auto;
    margin-bottom: @spacer;
    padding-right: @spacer * 2;
    padding-left: @spacer;
  }
}
