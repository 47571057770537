@import 'abstracts/variables';

.container {
  max-width: @max-width-lg;
  padding: 0 @spacer * 2;
  margin: 0 auto;
  margin-top: @spacer * 4;

  @media @below-md {
    box-shadow: @default-box-shadow;
    padding: @spacer * 2;
    border-radius: @border-radius-base - 2;
    background-color: @B000;
    margin-top: @spacer * 2;
  }
}

.empty-state {
  text-align: center;
  display: grid;
  place-items: center;

  .line {
    border-bottom: 1px solid @B200;
    width: 170px;
    padding-top: @spacer;

    @media @below-sm {
      width: 120px;
    }
  }

  .empty-state-graphic {
    margin-bottom: @spacer * 4;
  }

  &.max-width-medium {
    max-width: (100% / 4) * 3.5;
  }

  &.error {
    margin-bottom: @spacer * 8;
  }

  .empty-state-subtitle {
    max-width: 350px; // Match Figma
    color: @B800;

    @media @below-sm {
      margin-bottom: @spacer * 2.5;
    }
  }

  .empty-state-graphic-container {
    margin-top: @spacer * 6.5;
    margin-bottom: @spacer * 3;

    @media @below-sm {
      margin-bottom: @spacer * 3;
    }

    .empty-state-subtitle {
      max-width: 500px; // Match Figma
      margin-bottom: @spacer * 2.5;
      color: @B800;
    }
  }

  .empty-state-graphic-supplier-search {
    margin-left: @spacer * 4; // Visually align center
    max-width: @spacer * 14;
  }

  .empty-cart-cta-button-container {
    width: 100%;
  }

  .empty-cart-cta-button {
    max-width: 400px; // Match Figma
    width: 100%;
  }

  .empty-state-error {
    margin-left: @spacer * 2.5; // Visually align center
    margin-bottom: @spacer * 4;
  }

  .title {
    margin-bottom: @spacer;

    @media @below-sm {
      margin-bottom: @spacer * 2;
    }
  }

  .subtitle {
    font-size: @font-size-base;
    line-height: @line-height-base + 0.2;
    margin-bottom: @spacer * 3;
    color: @headings-color;

    @media @below-sm {
      margin-bottom: 0;
    }
  }
}

.carousel-stack {
  margin: @spacer * 6 0;

  .category-carousel {
    margin-bottom: @spacer * 6;
  }
}

.not-found-section {
  margin-bottom: @spacer * 8;

  @media @below-sm {
    padding: @spacer * 4;
    padding-bottom: 0;
  }
}
