@import 'variables';

// Max widths

.margin-x-auto() {
  margin-left: auto;
  margin-right: auto;
}

.max-width-m,
.max-width-lg {
  .margin-x-auto;
}

.max-width-m {
  max-width: @max-width-medium;
}

.max-width-lg {
  max-width: @max-width-lg;
}

.chaport-container {
  @media @below-sm {
    display: none !important;
  }
}

@media print {
  -webkit-filter: drop-shadow(4px 4px 1px #ccc);
  text-shadow: 4px 4px 1px #ccc;

  .chaport-container {
    display: none !important;
  }

  .chaport-window {
    display: none !important;
  }

  .chaport-message {
    display: none !important;
  }

  .chaport-launcher-button {
    display: none !important;
  }

  .hide-on-print {
    display: none;
  }

  .show-on-print {
    display: block !important;
  }
}

@page {
  margin: 1cm;
}

.rs-toast-container-bottom-center {
  @media @below-md {
    top: 0;
    padding: @spacer;

    .rs-notification {
      width: 100%;

      .rs-notification-content {
        max-width: 100%;
      }
    }
  }
}
