@import 'abstracts/helpers';
@import 'abstracts/variables';

@menu-width: 220px;

.confirm-modal {
  :global {
    .rs-modal-body {
      margin-top: @spacer;
      color: @B800;
    }
  }
}

.menu-styles {
  z-index: @z-index-chaport + 2 !important;
  max-width: min-content;
  width: @menu-width;
  height: 380px;

  :global {
    .rs-picker-select-menu-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: @menu-width;
      overflow: hidden;
    }
  }
}

.impersonation-toolbar:global(.rs-panel) {
  background-color: @B000;
  border-radius: @border-radius-base * 10;
  align-items: center;
  box-shadow: 0 3px 10px 5px rgba(36, 34, 34, 0.1);
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  bottom: 0;

  @media @below-md {
    bottom: 60px;
  }

  .customer-picker {
    width: @menu-width;
  }

  .impersonated-user-info {
    max-width: @menu-width + 30;

    h4,
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 20px;
      text-align: center;
    }

    h4 {
      color: @black;
      font-size: @font-size-large;
      margin-bottom: 1px;
    }

    p {
      color: @B600;
      font-size: @font-size-base;
    }
  }

  .user-avatar-name {
    height: 40px;
  }

  :global {
    .rs-panel-body {
      display: grid;
      grid-auto-flow: column;
      gap: 18px;
      align-items: center;
    }

    .rs-picker-menu.rs-picker-select-menu,
    .rs-picker-menu.rs-picker-check-menu {
      z-index: @z-index-chaport + 2;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
      height: 100%;
      padding-top: 9px;
      padding-bottom: 9px;

      svg {
        top: 9px;
      }
    }

    .rs-btn-icon.rs-btn {
      padding: 3px 3px;
      border: 3px solid var(--rs-avatar-bg);
      line-height: 16px;
      width: 40px;
      height: 40px;

      &.rs-btn-ghost:disabled,
      &.rs-btn-ghost.rs-btn-disabled {
        opacity: 1;

        svg {
          opacity: 0.3;
        }
      }
    }
  }
}

// To avoid toolbar moving to the right when scroll goes away due to modal
body:has(.force-vertical-scrollbar) {
  overflow-y: scroll;
  padding-right: 0 !important;
}

body:has(.impersonation-toolbar) {
  :global {
    .rs-toast-container.rs-toast-container-bottom-center {
      bottom: 120px;
    }
  }
}
