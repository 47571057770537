@import 'abstracts/variables';

.megamenu-popover-container {
  @shadow-y: 7.5px;

  position: absolute !important; // Make the position absolute to header container
  left: 0 !important; // Override rs-popover position styles
  top: 100% !important; // Override rs-popover position styles
  width: 100%;
  height: fit-content;
  border-top: none;
  margin-top: 0 !important; // Override rs-popover default margin top for placement-bottom prop
  box-shadow: 0 0 @shadow-y -@shadow-y rgba(darken(@primary, 40%), 0.1) !important; // Set shadows according to header component box-shadow
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.12)) !important; // Remove default popover drop-shadow filter to the design
  transform: translate(0, 1px) !important; // Remove the default transform value

  .megamenu-link {
    max-width: 60%;
    width: fit-content;
    font-size: @font-size-base;
    font-family: @font-family-base;
    color: @B800;
    cursor: pointer;

    &.active {
      color: @primary-darker;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.megamenu-cta-column {
  .image-container {
    border-radius: @border-radius-base;
  }

  .cta-column-link-content {
    text-decoration: none; // disable the default a tag underline

    .cta-link {
      font-weight: 700;
      font-family: @headings-font-family;
    }

    &:hover {
      .cta-link {
        text-decoration: underline;
      }
    }
  }
}
