@import 'abstracts/helpers';

button.mobile-navigation-customer-type-switcher {
  display: flex;
  align-items: center;
  gap: @spacer;
  width: 100%;
  padding: @spacer @spacer * 2;
  color: @dim-mid;

  .customer-type-switcher-content {
    display: flex;
    align-items: center;
    width: 100%;

    :global {
      .rs-divider {
        background: @dim-mid;
        margin: 0 5px 0 2px;
      }
    }
  }

  span {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  // Make the path thicker
  svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;

    &.home-icon {
      position: relative;
      top: -1px;
    }

    &.tool-icon {
      position: relative;
      top: -1px;
    }

    &.briefcase-icon {
      position: relative;
      width: 18px;
      height: 18px;
      top: -1px;
    }

    path {
      stroke-width: 2;
    }
  }

  svg.down-chevron-circle {
    width: 23px;
    height: 23px;
    min-width: fit-content; // Prevent from shrinking in smaller screens

    path {
      stroke-width: 1;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: @dim-mid;
    text-decoration-color: @dim-mid;

    svg.down-chevron-circle {
      rect {
        fill: @dim-mid-light;
      }
    }
  }
}

div.divider {
  margin: 0;
}
