@import 'abstracts/variables';
@import 'abstracts/droppe-theme';
@import 'utils/css-variables';
@import '~rsuite/styles/index'; // Rsuite common and components
@import 'utils/global-rsuite-overrides';
@import 'abstracts/layout';
@import 'abstracts/spacers';
@import 'abstracts/helpers';
@import 'abstracts/typography';

// Fix white gap at the bottom of the page
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  height: 100%;
}
