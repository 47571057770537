@import 'abstracts/variables';

@border: 1px solid @B300;

.footer-container {
  margin-top: @spacer * 4;
  width: 100%;
  background-color: @dim-mid;

  .footer {
    display: flex;
    flex-direction: column;
    color: @white;
    max-width: @max-width-lg;
    margin: 0 auto;
    padding: @spacer * 6 @spacer * 4 @spacer * 1.5 @spacer * 4;

    a {
      color: @white;
      text-decoration: none;

      &:hover {
        color: @primary;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      gap: @spacer * 10;
      width: 100%;

      .slogan-section {
        flex: 1;
        max-width: 380px;

        .logo {
          margin-left: 1px; // Magic number to align logo in pixel precision with footer headline
        }
      }

      &.logos {
        justify-content: flex-end;
      }

      @media @below-md {
        flex-direction: column;
        padding: @spacer * 2;
      }
    }

    .link-lists {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: @spacer * 4;
      width: 100%;

      > div {
        max-width: 300px;

        :global(.h6) {
          margin-bottom: @spacer * 1.5;
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            margin-top: (@spacer / 2);
          }

          @media @below-md {
            li a {
              padding: @spacer 0;
              margin-bottom: @spacer * 0.5;
              display: inline-block;
              width: 100%;
            }
          }
        }

        @media @below-md {
          flex: 1;
          min-width: 150px;

          h6 {
            margin-bottom: @spacer;
          }
        }
      }
    }

    .logos {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: @spacer * 2;
      margin-top: @spacer * 2;

      // Hide unnecessary span on top of image from Img component
      span > span {
        display: none !important;
      }

      // Constraints for logo sizes based on Figma
      img {
        max-width: 200px !important;
        max-height: 65px !important;
      }

      @media @below-md {
        justify-content: flex-start;

        img {
          max-height: 55px;
        }
      }
    }

    // Specific styles for the CheckoutFooter component
    &.-checkout {
      background-color: transparent;
      border-top: 1px solid @B200;
      padding: @spacer * 2 @spacer * 2 @spacer * 3 @spacer * 2;

      .footer-safe-place-text {
        flex-wrap: nowrap;
      }

      @media @below-md {
        padding-bottom: @spacer * 10; // Add padding for "Continue" sticky button on mobile and tablet
      }

      > div {
        width: 100%;
      }

      svg,
      svg path {
        fill: @white;
        stroke: @white;
      }
    }

    @media @below-md {
      padding-bottom: 0;
    }
  }
}
