@import 'abstracts/variables';
@import './ProfileIconPicker.module';

.signin-popover {
  .popover;

  width: 220px;
  padding: 0 !important;

  :global {
    .rs-popover-content {
      font-size: @font-size-base;
      padding: @spacer * 2;
    }

    .rs-popover-arrow {
      display: none !important; // Hide popover tooltip
    }
  }
}

.highlight-text {
  color: @primary-darker;
}

.title {
  color: @dim-mid;
}

.content-container {
  .text-section {
    max-width: 170px;
    white-space: normal;
  }
}
