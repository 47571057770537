@import 'abstracts/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: @spacer @spacer * 40;
  margin-bottom: @spacer;
  border-bottom: 1px solid @B200;
}

.mobile-header {
  padding: @spacer @spacer * 2;
}
