@import 'abstracts/variables';
@import 'abstracts/helpers';

.modal-section {
  :global {
    .rs-modal-content {
      padding: @spacer * 4 0;
      margin-top: @spacer * 4;
    }
  }

  // Remove the width for the contacts modal in the mobile blocker for smaller devices
  @media @below-sm {
    max-width: 100% !important; // To override the default
    margin-top: -(@spacer * 2) !important; // Align the modal more towards top to match the design

    :global {
      .rs-modal-content {
        height: 103.5vh; // Extra 3.5vh height to compensate -30px margin-top so it can be on whole screen
        padding: @spacer * 2 0;
        margin-top: 0;
      }

      .rs-modal-body {
        margin-top: @spacer;
      }
    }
  }

  .mobile-header {
    display: none;
    text-align: center;
    padding: @spacer * 2;
    font-size: @font-size-large;
    border-bottom: 1px solid @B200;

    @media @below-sm {
      display: block;
    }
  }

  .container {
    max-width: 450px;

    @media @below-sm {
      margin-top: @spacer * 10;
      max-width: 100%;
      padding: 0 @spacer * 3;
    }

    a:hover {
      text-decoration: none;
    }

    .text {
      font-size: @font-size-large;
      color: @B800;

      @media @below-sm {
        font-size: @font-size-base;
        margin-top: @spacer * 2;
      }

      a {
        text-decoration: underline;
        color: @primary-darker;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
